<template>
  <div v-if="id && id !== 'all'">
    <div class="flix-pull-right">
      <toggle :data="show" :onChange="function() { changeShow() }">
        <template v-slot:0>{{ $tc('message.form', 1) }}</template>
        <template v-slot:1>{{ $tc('message.calendar', 1) }}</template>
      </toggle>
    </div>
    <h3 class="flix-html-h2">{{ $t('message.createNew', {name: $tc('message.appointments', 1)}) }}</h3>
    <transition name="flixFadeIn">
      <div :key="show">
        <div class="flix-clearfix" />
        <addForm :id="id" v-if="show == $tc('message.form', 1)"/>
        <addCalendar :id="id" v-else/>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    addForm () { return import('@/components/crm/addCustomer/form') },
    addCalendar () { return import('@/components/crm/addCustomer/calendar') }
  },
  props: {
    id: [String, Number]
  },
  data () {
    return {
      show: this.$tc('message.form', 1)
    }
  },
  methods: {
    changeShow () {
      if (this.show === this.$tc('message.form', 1)) {
        this.show = this.$tc('message.calendar', 1)
        return false
      }
      this.show = this.$tc('message.form', 1)
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
